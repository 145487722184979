import { format, isAfter } from 'date-fns'
import { groupBy, head } from 'lodash-es'
import fetchGql from '~/utils/fetchGql'
import type EventCardI from '@/types/Storyblok/General/EventCard'
import type { GetAllEventsQueryVariables } from '~/gqlTypes/types'
import getAllEvents from '~/gql/getAllEvents.gql'

const groupByMulti = (list: any, values: any) => {
  if (!values.length) {
    return list
  }

  const groupedByFirstValue = groupBy(list, head(values))
  const rest = values.slice(1)

  for (const prop in groupedByFirstValue) {
    groupedByFirstValue[prop] = groupByMulti(groupedByFirstValue[prop], rest)
  }

  return groupedByFirstValue
}

const parseYearMonth = (event: EventCardI) => {
  const date = new Date(event.content.date)
  if (isNaN(date.getTime())) {
    return 'Undefiend year'
  }

  return format(date, 'yyyy')
}

const eventInFuture = (event: EventCardI) => {
  const currentDate = format(new Date(), 'yyyy-MM-dd')
  const eventDate = new Date(event.content.date)

  return isAfter(new Date(currentDate), eventDate) ? 'pastEvents' : 'futureEvents'
}

const parseData = (data: EventCardI[]) => {
  const result = {
    futureEvents: {},
    pastEvents: {}
  }

  const groupedByYearMonth = groupByMulti(data, [eventInFuture, parseYearMonth])

  result.pastEvents = groupedByYearMonth.pastEvents
  result.futureEvents = groupedByYearMonth.futureEvents

  return result
}

export const useFetchInvestorEvents = () => {
  const fetchInvestorEvents = (language: string, type?: string, perPage?: number, futureEvents = false) => {
    const filter = {} as any

    if (typeof type !== 'undefined') {
      filter.eventType = { in: type }
    }

    if (futureEvents) {
      const today = format(new Date(), 'yyyy-MM-dd kk:mm')
      filter.date = { gt_date: today }
    }

    const variables: GetAllEventsQueryVariables = {
      starts_with: language === 'en' ? '' : `${language}/*`,
      sortBy: 'content.date:asc',
      filter,
      perPage
    }

    return fetchGql(getAllEvents, variables, true).then((response: any) => ({
      events: parseData(response.InvestoreventsItems.items)
    }))
  }

  return { fetchInvestorEvents }
}
